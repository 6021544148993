<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <b-form-select v-model="filterItem" :options="filterItems">
              <template v-slot:first>
                <option value="" disabled>
                  {{ $t("NOTIFICATIONS.SELECT_NOTIFICATION_TYPE") }}
                </option>
              </template>
            </b-form-select>
            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Clear"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";

export default {
  name: "notifications",
  components: {},
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "created",
          formatter: Common.idObjectFormatter,
          label: this.$t("COMMON.CREATED"),
          sortingKey: "el.created",
          sortable: true,
        },
        {
          key: "title",
          label: this.$t("NOTIFICATIONS.TITLE"),
          sortingKey: "el.title",
          sortable: true,
        },
        {
          key: "body",
          label: this.$t("TEMPLATES.BODY"),
          sortingKey: "el.body",
          sortable: true,
        },
        {
          key: "source",
          label: this.$t("NOTIFICATIONS.SOURCE"),
          sortingKey: "el.source",
          sortable: true,
        },
        {
          key: "destination",
          label: this.$t("NOTIFICATIONS.DESTINATION"),
          sortingKey: "el.destination",
          sortable: true,
        },
        {
          key: "notificationStateId",
          label: this.$t("NOTIFICATIONS.NOTIFICATION_STATE_NAME"),
          formatter: this.stateFormatter,
          sortingKey: "el.notificationState.id",
          sortable: true,
        },
      ],

      response: {
        data: [],
        totalCount: 1,
      },

      getList: {
        resource: "/platform/api/notification",
        requestType: "GET",
        requestParams: { enrich: true },
      },
      //getNotificationMethod: { resource: "/platform/api/dictionary/com.gracelogic.platform.notification.model.NotificationMethod", requestType: "GET", requestParams: {} },
      getNotificationState: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.notification.model.NotificationState",
        requestType: "GET",
        requestParams: {},
      },

      calculate: false,

      currentPage: 1,
      perPage: 10,
      showPerPageArray: [10, 50, 100],

      selectedItem: null,
      filterItems: [],
      filterItem: "",

      showTableOverlay: true,
      stateMap: {},
    };
  },
  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },
  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    this.loadNotificationState();
    this.loadList();
  },
  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.NOTIFICATIONS") },
    ]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },
  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    loadNotificationState: function () {
      this.$store
        .dispatch(API_REQUEST, this.getNotificationState)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.stateMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.filterItems.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },
    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;

      this.getList.requestParams["notificationStateId"] =
        this.filterItem.length > 0 ? this.filterItem : null;
      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["notificationStateId"];
      this.filterItem = "";

      this.loadList();
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },
    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;
      this.loadList();
    },
    stateFormatter: function (value) {
      if (value && value in this.stateMap)
        return this.stateMap[value].nameLocalized;

      return "";
    },
  },
};
</script>
